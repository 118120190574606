import React from "react";
import { ExpandLess } from "@mui/icons-material";
import { IconButton, useMediaQuery, Box } from "@mui/material";
import useScrollToTop from "../../../hooks/useScrollToTop";
import SideBar from "../SideBar/SideBar";
import Footer from "../SideBar/Footer/Footer";
import Infos from "../SideBar/Infos/Infos";

import "./main.scss";

function Main({ children }) {
  const { showTopBtn, goToTop } = useScrollToTop();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: isMobile ? "block" : "flex",
        width: "100vw",
      }}
    >
      {showTopBtn && isMobile && (
        <IconButton
          sx={{
            color: "#000",
            position: "fixed",
            bottom: "40px",
            right: "10px",
            backgroundColor: "#FFF",
            zIndex: 100,
          }}
          onClick={goToTop}
        >
          <ExpandLess />
        </IconButton>
      )}
      <SideBar />
      <Box className="main__container">{children}</Box>
      {isMobile ? (
        <>
          <Infos />
          <Footer />
        </>
      ) : null}
    </Box>
  );
}

export default Main;

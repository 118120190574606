import { createTheme } from "@mui/material/styles";

const t = createTheme();

t.typography.h1 = {
  margin: "1rem auto",
  color: "#fffde7",
  textAlign: "center",
  fontFamily: "Dosis",
  fontSize: "1.3rem",
  fontWeight: "800",
  letterSpacing: "0.1em",
  textTransform: "uppercase",
  lineHeight: "1",
};
t.typography.h2 = {
  margin: "1rem auto",
  color: "#fffde7",
  textAlign: "center",
  fontFamily: "Dosis",
  fontSize: "3rem",
  fontWeight: "800",
  letterSpacing: "0.2em",
  textTransform: "uppercase",
  lineHeight: "1.5",
  textShadow:
    "0px 0px 1px rgba(255,253,231,1),0px 0px 15px rgba(255, 253, 231, 0.43),0px 0px 51px rgba(255,253,231,0.2)",
  [t.breakpoints.down("md")]: {
    fontSize: "2rem",
  },
};
t.typography.h3 = {
  color: "#fffde7",
  fontFamily: "Dosis",
  fontSize: "1.8rem",
  fontWeight: "700",
  letterSpacing: "0.2em",
  textTransform: "uppercase",
  lineHeight: "1.5",
  [t.breakpoints.down("md")]: {
    fontSize: "1rem",

    letterSpacing: "0.1em",
  },
};
t.typography.h4 = {
  color: "#fffde7",
  textAlign: "center",
  margin: "1rem auto",
  fontFamily: "Dosis",
  fontSize: "1.8rem",
  fontWeight: "800",
  letterSpacing: "0.2em",
  textTransform: "uppercase",
  lineHeight: "1.5",
  [t.breakpoints.down("md")]: {
    fontSize: "1.3rem",
  },
};
t.typography.h5 = {
  color: "#fffde7",
  padding: "0.5rem",
  textAlign: "left",
  fontSize: "1.3rem",
  fontFamily: "Dosis",
  fontWeight: "800",
  letterSpacing: "0.2em",
  textTransform: "uppercase",
  lineHeight: "1.5",
  [t.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
};
t.typography.subtitle1 = {
  color: "#fffde7",
  fontFamily: "Dosis",
  fontSize: "1rem",
  fontWeight: "400",
  letterSpacing: "0.2em",
  lineHeight: "1",
  [t.breakpoints.down("md")]: {
    fontSize: "0.8rem",
  },
};
t.typography.subtitle2 = {
  color: "#fffde7",
  fontFamily: "Dosis",
  fontSize: "1rem",
  fontWeight: "400",
  letterSpacing: "0",
  lineHeight: "1",
  [t.breakpoints.down("md")]: {
    fontSize: "0.8rem",
  },
};
t.typography.subtitle1 = {
  color: "#fffde7",
  fontFamily: "Dosis",
  fontSize: "1rem",
  fontWeight: "400",
  letterSpacing: "0.2em",
  lineHeight: "1",
  [t.breakpoints.down("md")]: {
    fontSize: "0.8rem",
  },
};
t.typography.body1 = {
  color: "#fffde7",
  fontFamily: "Dosis",
  lineHeight: "1",
  fontSize: "1.3rem",
  [t.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
};
t.typography.body2 = {
  color: "#fffde7",
  fontFamily: "Dosis",
  lineHeight: "1",
  fontSize: "1.5rem",
  fontWeight: "700",
  [t.breakpoints.down("md")]: {
    fontSize: "1.2rem",
  },
};

const theme = createTheme({
  ...t,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          elevation: 0,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: "0.5rem",
          width: "30%",
          height: "100%",
          color: "#fffde7",
          textAlign: "center",
          background:
            "linear-gradient(#0e0e0e 65%, #030303 66%) top 0.03em center / 100% 0.3em repeat-y;",
          margin: "1rem auto",
          border: `8px solid #fffde7`,
          borderRadius: "5px",
          [t.breakpoints.down("md")]: {
            width: "100%",
            maxHeight: "650px",
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          position: "relative",
        },
      },
    },
  },
});

export default theme;

import React from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, Outlet } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import AdminMain from "../AdminMain/AdminMain";
import "./menuPage.scss";

function MenuPage() {
  return (
    <>
      <Helmet>
        <title>Admin - Menus</title>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href="/admin/menus" />
      </Helmet>
      <AdminMain>
        <div className="menupage">
          <h2 className="menupage__title">Gestion des Boissons</h2>
          <div className="menupage__content">
            <nav className="menupage__content-nav">
              <Tooltip title="Ajouter, Modifier, Supprimer les plats de la carte">
                <span>
                  <button disabled className="menupage__content-nav-link">
                    Gestion de la carte des plats
                  </button>
                </span>
              </Tooltip>
              <Tooltip title="Ajouter, Modifier, Supprimer les boissons de la carte">
                <span>
                  <NavLink
                    to="drink"
                    className={({ isActive }) =>
                      isActive
                        ? "menupage__content-nav-link active"
                        : "menupage__content-nav-link"
                    }
                  >
                    Gestion de la carte des boissons
                  </NavLink>
                </span>
              </Tooltip>
            </nav>
          </div>
          <Outlet />
        </div>
      </AdminMain>
    </>
  );
}
export default MenuPage;

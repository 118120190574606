import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import AdminMain from "../AdminMain/AdminMain";
import Button from "../../common/Buttons/Button/Button";
import "./adminhomepage.scss";

function AdminHomePage() {
  return (
    <>
      <Helmet>
        <title>Admin - Accueil</title>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href="/admin/home" />
      </Helmet>
      <AdminMain>
        <div className="admin__homepage-container">
          <Tooltip
            title="Gérer toute la partie menu et boisson"
            placement="bottom"
          >
            <Link to="/admin/menus/drink">
              <Button
                classname="homepage-button"
                name="Gestion des Boissons"
                type="button"
              />
            </Link>
          </Tooltip>
          <Tooltip
            title="Ajouter, Modifier ou Supprimer un évènement"
            placement="bottom"
          >
            <Link to="/admin/event">
              <Button
                classname="homepage-button"
                name="Gestion des events"
                type="button"
              />
            </Link>
          </Tooltip>
        </div>
      </AdminMain>
    </>
  );
}
export default AdminHomePage;

import apiAxios from "./index";

/**
 * function used to make a http request to get all videos
 * @returns response with videos in an array of object
 */
export async function getAllVideos() {
  try {
    const response = await apiAxios.get("/videos");
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to get one video
 * @param {string} id id of the video you want to get
 * @returns response with video in an object
 */
export async function getOneVideo(id) {
  try {
    const response = await apiAxios.get(`/videos/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to create a video
 * @param {object} videoDatas video informations (name)
 * @returns response with new video in an object
 */
export async function createVideo(videoDatas) {
  try {
    const response = await apiAxios.post("/videos", videoDatas);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to update a video
 * @param {string} id id of the video you want to update
 * @param {object} videoDatas video informations (name)
 * @returns response with updated video in an object
 */
export async function updateVideo(id, videoDatas) {
  try {
    const response = await apiAxios.patch(`/videos/${id}`, videoDatas);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to delete a video
 * @param {string} id id of the video you want to delete
 * @returns response with an empty object
 */
export async function deleteVideo(id) {
  try {
    const response = await apiAxios.delete(`/videos/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../UserComponents/HomePage/HomePage";
import EventConcertPage from "../UserComponents/EventConcertPage/EventConcertPage";
import ContactPage from "../UserComponents/ContactPage/ContactPage";
import ConfPage from "../UserComponents/ConfPol/ConfPol";
import NotFoundPage from "../common/NotFoundPage/NotFoundPage";
import RequireAuth from "../AdminComponents/RequireAuth/RequireAuth";
import LoginPage from "../AdminComponents/LoginPage/LoginPage";
import AdminHomePage from "../AdminComponents/AdminHomePage/AdminHomePage";
import EventPage from "../AdminComponents/EventPage/EventPage";
import MenuPage from "../AdminComponents/MenuPage/MenuPage";
import CardContainer from "../../container/CardContainer";
import UpdateEvent from "../AdminComponents/EventPage/UpdateEvent/UpdateEvent";
import AddEvent from "../AdminComponents/EventPage/AddEvent/AddEvent";
import DeleteEvent from "../AdminComponents/EventPage/DeleteEvent/DeleteEvent";
import CartePage from "../UserComponents/CartePage/CartePage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/drinks" element={<CartePage cardType="drink" />} />
      <Route path="/events" element={<EventConcertPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/politiques-confidentialite" element={<ConfPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/admin" element={<RequireAuth />}>
        <Route path="home" element={<AdminHomePage />} />
        <Route path="event" element={<EventPage />}>
          <Route path="add" element={<AddEvent />} />
          <Route path="update" element={<UpdateEvent />} />
          <Route path="delete" element={<DeleteEvent />} />
        </Route>
        <Route path="menus" element={<MenuPage />}>
          <Route path="drink" element={<CardContainer />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;

import { Typography, Stack, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Main from "../../common/Main/Main";
import ArticleEvent from "../ArticleEvent/ArticleEvent";
import { getEvents } from "./const";
import "./eventconcertpage.scss";

function EventConcertPage() {
  const [events, setEvents] = useState([]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const getAllEvents = async () => {
    const resultEvents = await getEvents();
    if (resultEvents.length > 0) setEvents(resultEvents);
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  return (
    <>
      <Helmet>
        <title>Maëly&apos;s Café - Evènements et concerts</title>
        <meta
          name="description"
          content="To find out about all the upcoming events and concerts offered by the Maelys Cafe bar"
        />
        <link rel="canonical" href="/events" />
      </Helmet>
      <Main>
        <Typography variant="h2">Evénements et concerts</Typography>
        <Stack
          sx={{
            width: "100%",
            flexDirection: isMobile ? "column" : "row",
            flexWrap: "wrap",
          }}
        >
          {events.map((item) => (
            <ArticleEvent
              image={item.photos ? item.photos[0] : null}
              date={item.date}
              title={item.title}
              video={item.videos ? item.videos[0] : null}
              group={item.group}
              description={item.description}
              key={item.id}
              id={item.id}
            />
          ))}
        </Stack>
      </Main>
    </>
  );
}
export default EventConcertPage;

import React from "react";
import { NavLink } from "react-router-dom";
import "./nav.scss";

function Nav() {
  return (
    <nav className="nav__container">
      <NavLink
        to="/"
        className={({ isActive }) =>
          `nav-link ${isActive ? "nav-link--active" : ""}`
        }
      >
        <p>Accueil</p>
      </NavLink>
      <NavLink
        to="/drinks"
        className={({ isActive }) =>
          `nav-link ${isActive ? "nav-link--active" : ""}`
        }
      >
        <p>Nos Boissons</p>
      </NavLink>
      <NavLink
        to="/events"
        className={({ isActive }) =>
          `nav-link ${isActive ? "nav-link--active" : ""}`
        }
      >
        <p>Evènements et concerts</p>
      </NavLink>
      <NavLink
        to="/contact"
        className={({ isActive }) =>
          `nav-link ${isActive ? "nav-link--active" : ""}`
        }
      >
        <p>Nous contacter</p>
      </NavLink>
    </nav>
  );
}
export default Nav;

/* eslint-disable no-unused-vars */
import { Box, CardContent, CardMedia, Typography, styled } from "@mui/material";
import React from "react";
import { getFrFormat } from "../../../utils/dates/dates";
import "./articleevent.scss";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "0.5rem",
  width: "30%",
  color: "#fffde7",
  textAlign: "center",
  background:
    "linear-gradient(#0e0e0e 65%, #030303 66%) top 0.03em center / 100% 0.3em repeat-y;",
  margin: "1rem auto",
  border: `8px solid #fffde7`,
  borderRadius: "5px",
  boxShadow:
    "0px 0px 6px rgba(255,253,231,0.6), inset 0px 0px 6px rgba(255,253,231,0.6), 0px 0px 16px rgba(255,253,231,0.4), inset 0px 0px 16px rgba(255,253,231,0.4), 0px 0px 32px rgba(255,253,231,0.2), inset 0px 0px 32px rgba(255,253,231,0.2)",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    maxHeight: "650px",
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  "&:before": {
    content: `""`,
    display: "block",
    width: "60px",
    height: "20px",
    margin: "auto",
    background: " rgba(255, 255, 200, 0.6)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.4)",
    position: "absolute",
    top: "15px",
    left: "5px",
    transform: "rotate(-45deg)",
  },
  "&:after": {
    content: `""`,
    display: "block",
    width: "64px",
    height: "20px",
    margin: "auto",
    background: "rgba(255, 255, 200, 0.6)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.4)",
    position: "absolute",
    bottom: "15px",
    right: "0px",
    transform: "rotate(-35deg)",
  },
}));

function ArticleEvent({ image, date, title }) {
  const getCorrectDateFormat = (dateString) => {
    const goodFormat = getFrFormat(dateString);
    return goodFormat;
  };

  return (
    <StyledBox>
      <Typography variant="h5">{getCorrectDateFormat(date)}</Typography>
      <Typography variant="h4">{title}</Typography>
      <StyledCardContent>
        {image && (
          <CardMedia
            component="img"
            image={image.url}
            alt="green iguana"
            sx={{
              margin: "auto",
              objectFit: "contain",
              width: "95%",
              height: "95%",
            }}
          />
        )}
      </StyledCardContent>
    </StyledBox>
  );
}

export default ArticleEvent;

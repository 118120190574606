import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../common/Buttons/IconButton/IconButton";
import EditMenupartModal from "../../Modals/EditMenupartModal";
import EditMealModal from "../../Modals/EditMealModal";
import DeleteMealModal from "../../Modals/DeleteMealModal";
import {
  actionSetMenupartInfos,
  actionSetPhotoInfos,
  actionSetMealInfos,
  actionInitNewMeal,
} from "../../../../redux/actions";
import "./card.scss";

function Card({ allMenuparts, drink }) {
  const dispatch = useDispatch();
  const [openEditMenupartModal, setOpenEditMenupartModal] = useState(false);
  const [openEditMealModal, setOpenEditMealModal] = useState(false);
  const [openDeleteMealModal, setOpenDeleteMealModal] = useState(false);

  const renderEditMenupartModal = () => (
    <EditMenupartModal
      openModal={openEditMenupartModal}
      closeModal={() => setOpenEditMenupartModal(false)}
      drink={drink}
    />
  );
  const renderEditMealModal = () => {
    return (
      <EditMealModal
        openModal={openEditMealModal}
        closeModal={() => setOpenEditMealModal(false)}
        drink={drink}
      />
    );
  };
  const renderDeleteMealModal = () => {
    return (
      <DeleteMealModal
        openModal={openDeleteMealModal}
        closeModal={() => setOpenDeleteMealModal(false)}
        modalTitle={drink ? "Supprimer la boisson" : "Supprimer le plat"}
      />
    );
  };

  const handleOpenModal = (modal, item = null) => {
    switch (modal) {
      case "createMenupart":
      case "editMenupart":
        if (item) {
          dispatch(actionSetMenupartInfos(item));
          dispatch(actionSetPhotoInfos(item.photos[0]));
        }
        setOpenEditMenupartModal(true);
        break;
      case "createMeal":
        dispatch(actionInitNewMeal(item));
        setOpenEditMealModal(true);
        break;
      case "editMeal":
        dispatch(actionSetMealInfos(item));
        setOpenEditMealModal(true);
        break;
      case "deleteMeal":
        if (item) {
          dispatch(actionSetMealInfos(item));
        }
        setOpenDeleteMealModal(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className="card-page">
      {renderEditMenupartModal()}
      {renderEditMealModal()}
      {renderDeleteMealModal()}
      <div className="card-page__header">
        <button
          type="button"
          className="card-page__header-button"
          onClick={() => handleOpenModal("createMenupart")}
        >
          <FontAwesomeIcon icon={faPlus} size="2x" />
          <span className="card-page__header-button-span">
            Ajouter une catégorie
          </span>
        </button>
      </div>
      {allMenuparts
        .sort((a, b) => a.ordinal - b.ordinal)
        .map((menupart) => (
          <section key={menupart.id} className="card-page__section">
            <div className="card-page__section-header">
              <h3 className="card-page__section-header-title">
                {menupart.name}
              </h3>
              <div className="card-page__section-header-div">
                <IconButton
                  tooltipText="Modifier la catégorie"
                  placement="left"
                  classname="card-page__section-header-button"
                  buttonSize="2x"
                  icon="faPen"
                  onClick={() => handleOpenModal("editMenupart", menupart)}
                />
                <IconButton
                  tooltipText={
                    drink ? "Ajouter une boisson" : "Ajouter un plat"
                  }
                  placement="right"
                  classname="card-page__section-header-button"
                  buttonSize="2x"
                  icon="faPlus"
                  onClick={() => handleOpenModal("createMeal", menupart)}
                />
              </div>
            </div>
            {menupart.meals
              .sort((a, b) => a.id - b.id)
              .map((meal) => (
                <article key={meal.id} className="card-page__article">
                  <div className="card-page__article-section">
                    <div className="card-page__article-section-info">
                      <div className="card-page__article-section-info-title">
                        <p className="card-page__article-section-info-title-text">
                          {meal.title}
                        </p>
                        {!meal.halfprice && (
                          <p className="card-page__article-section-info-title-normal_price">
                            {meal.price} €
                          </p>
                        )}
                        {drink && meal.halfprice && (
                          <>
                            {meal.quarterprice && (
                              <div className="card-page__article-section-info-title-container">
                                <p>25cl</p>
                                <p className="card-page__article-section-info-title-price">
                                  {meal.quarterprice} €
                                </p>
                              </div>
                            )}
                            {meal.halfprice && (
                              <div className="card-page__article-section-info-title-container">
                                <p>50cl</p>
                                <p className="card-page__article-section-info-title-price">
                                  {meal.halfprice} €
                                </p>
                              </div>
                            )}
                            {meal.literprice && (
                              <div className="card-page__article-section-info-title-container">
                                <p>1L</p>
                                <p className="card-page__article-section-info-title-price">
                                  {meal.literprice} €
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <p className="card-page__article-section-info-title-description">
                        {meal.description}
                      </p>
                    </div>
                    <div className="card-page__article-section-button">
                      <IconButton
                        tooltipText={
                          drink ? "Modifier la boisson" : "Modifier le plat"
                        }
                        placement="left"
                        classname="card-page__article-button"
                        buttonSize="2x"
                        icon="faPen"
                        onClick={() => handleOpenModal("editMeal", meal)}
                      />
                      <IconButton
                        tooltipText={
                          drink ? "Supprimer la boisson" : "Supprimer le plat"
                        }
                        placement="right"
                        classname="card-page__article-button"
                        buttonSize="2x"
                        icon="faTrash"
                        onClick={() => handleOpenModal("deleteMeal", meal)}
                      />
                    </div>
                  </div>
                </article>
              ))}
          </section>
        ))}
    </div>
  );
}

export default Card;

import moment from "moment";
import { Helmet } from "react-helmet-async";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetOneEvent,
  actionGetAllEvents,
  actionDeleteEvent,
  actionUpdateEventInput,
} from "../../../../redux/actions";
import Button from "../../../common/Buttons/Button/Button";
import "./deleteEvent.scss";

function DeleteEvent() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionGetAllEvents());
  }, []);

  const { allEvents } = useSelector((state) => state.event);

  const handleSelectValueChange = (event) => {
    dispatch(actionUpdateEventInput("id", event.target.value));
    dispatch(actionGetOneEvent());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(actionDeleteEvent());
  };

  return (
    <>
      <Helmet>
        <title>Admin - Event</title>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href="/admin/event/delete" />
      </Helmet>
      <div className="updateEvent-page">
        <h3 className="updateEvent-page__title">Supprimer un évènement</h3>
        <form
          action=""
          className="updateEvent-page__form"
          onSubmit={handleSubmit}
        >
          <h3 className="updateEvent-page__form-title">
            Sélectionner l&apos;évènement
          </h3>
          <select
            id="pet-select"
            className="updateEvent-page__form-select"
            onChange={handleSelectValueChange}
          >
            <option value="">--Choisissez un évènement--</option>
            {allEvents.map((item) => (
              <option value={item.id} key={item.id}>
                {item.title} - {moment(item.date).format("DD/MM/YYYY")}
              </option>
            ))}
          </select>
          <div className="updateEvent-page__form-button">
            <Button
              classname="normal-button cancel"
              type="submit"
              name="Supprimer l'évènement"
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default DeleteEvent;

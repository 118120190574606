/* eslint-disable default-param-last */
import * as actions from "../actions";

const initialState = {
  id: 0,
  title: "",
  url: "",
  event_id: 0,
};

/**
 * function used to call action as appropriate
 * @param {object} state redux state
 * @param {object} action redux actions
 * @returns a new state
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_VIDEO_INFOS:
      if (action.payload.id) {
        return {
          ...state,
          id: action.payload.id,
          title: action.payload.title,
          url: action.payload.url,
          event_id: action.payload.event_id,
        };
      }
      return {
        ...state,
        title: action.payload.title,
        url: action.payload.url,
        event_id: action.payload.event_id,
      };
    case actions.SET_VIDEO_OWNER_ID:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case actions.RESET_VIDEO:
      return {
        ...state,
        id: initialState.id,
        title: initialState.title,
        url: initialState.url,
        event_id: initialState.event_id,
      };
    default:
      return state;
  }
}

export default reducer;

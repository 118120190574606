import React from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, Outlet } from "react-router-dom";
import AdminMain from "../AdminMain/AdminMain";
import "./eventPage.scss";

function EventPage() {
  return (
    <>
      <Helmet>
        <title>Admin - Event</title>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href="/admin/event" />
      </Helmet>
      <AdminMain>
        <div className="eventpage">
          <h2 className="eventpage__title">
            Gestion des évènements et concerts
          </h2>
          <div className="eventpage__content">
            <nav className="eventpage__content-nav">
              <NavLink
                to="add"
                className={({ isActive }) =>
                  isActive
                    ? "eventpage__content-nav-link active"
                    : "eventpage__content-nav-link"
                }
              >
                Créer un évènement
              </NavLink>
              <NavLink
                to="update"
                className={({ isActive }) =>
                  isActive
                    ? "eventpage__content-nav-link active"
                    : "eventpage__content-nav-link"
                }
              >
                Modifier un évènement
              </NavLink>
              <NavLink
                to="delete"
                className={({ isActive }) =>
                  isActive
                    ? "eventpage__content-nav-link active"
                    : "eventpage__content-nav-link"
                }
              >
                Supprimer un évènement
              </NavLink>
            </nav>
          </div>
          <Outlet />
        </div>
      </AdminMain>
    </>
  );
}

export default EventPage;

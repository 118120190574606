/* eslint-disable camelcase */
import {
  getAllMeals,
  getOneMeal,
  createMeal,
  updateMeal,
  deleteMeal,
} from "../../utils/requests/mealRequest";
import * as actions from "../actions";

const mealMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case actions.GET_ALL_MEALS: {
      const response = await getAllMeals();
      if (response.status === 200) {
        store.dispatch(actions.actionSetAllMeals(response.data));
      }
      return;
    }

    case actions.GET_ONE_MEAL: {
      const { meal } = store.getState();
      const response = await getOneMeal(meal.id);
      if (response.status === 200) {
        store.dispatch(actions.actionGetOneMeal(response.data));
        store.dispatch(actions.actionSetMealInfos(response.data));
      }
      return;
    }

    case actions.CREATE_MENUPART_MEAL: {
      const { meal } = store.getState();
      const {
        title,
        quarterprice,
        halfprice,
        literprice,
        price,
        description,
        menupartId,
      } = meal;
      const mealDatas = {
        title,
        quarterprice,
        halfprice,
        literprice,
        price,
        description,
        menupart_id: menupartId,
      };
      const response = await createMeal(mealDatas);
      if (response.status === 200) {
        store.dispatch(actions.actionResetMeal());
        store.dispatch(actions.actionGetOneCard());
      }
      return;
    }

    case actions.UPDATE_MEAL: {
      const { meal } = store.getState();
      const {
        title,
        quarterprice,
        halfprice,
        literprice,
        price,
        description,
        menupartId,
      } = meal;
      const mealDatas = {
        title,
        quarterprice,
        halfprice,
        literprice,
        price,
        description,
        menupart_id: menupartId,
      };
      const response = await updateMeal(meal.id, mealDatas);
      if (response.status === 200) {
        store.dispatch(actions.actionResetMeal());
        store.dispatch(actions.actionGetOneCard());
      }
      return;
    }

    case actions.DELETE_MEAL: {
      const { meal } = store.getState();
      const response = await deleteMeal(meal.id);
      if (response.status === 204) {
        store.dispatch(actions.actionResetMeal());
        store.dispatch(actions.actionGetOneCard());
      }
      return;
    }

    default:
      next(action);
  }
};

export default mealMiddleware;

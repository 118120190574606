import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  actionSetPhotoInfos,
  actionSetVideoInfos,
  actionGetAllEvents,
  actionUpdateEventInput,
  actionGetOneEvent,
  actionUpdateEvent,
  actionResetPhoto,
  actionResetVideo,
  actionRemoveVideoFromFirebase,
  actionRemovePhotoFromFirebase,
} from "../../../../redux/actions";
import Button from "../../../common/Buttons/Button/Button";
import FileInput from "../../FileInput/FileInput";
import "./updateEvent.scss";

function UpdateEvent() {
  const selectRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionGetAllEvents());
    dispatch(actionResetPhoto());
    dispatch(actionResetVideo());
  }, []);

  const { allEvents, id, title, date, group, description } = useSelector(
    (state) => state.event
  );
  const photo = useSelector((state) => state.photo);
  const video = useSelector((state) => state.video);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isInputPhotoDisabled, setIsInputPhotoDisabled] = useState(false);
  const [isInputVideoDisabled, setIsInputVideoDisabled] = useState(false);

  useEffect(() => {
    if (photo.title && video.title) {
      setIsInputPhotoDisabled(true);
      setIsInputVideoDisabled(true);
      return;
    }
    if (photo.title || video.title) {
      if (photo.title) {
        setIsInputPhotoDisabled(true);
        setIsInputVideoDisabled(false);
      }
      if (video.title) {
        setIsInputPhotoDisabled(false);
        setIsInputVideoDisabled(true);
      }
      return;
    }
    setIsInputPhotoDisabled(false);
    setIsInputVideoDisabled(false);
  }, [photo, video]);

  const dateInFormat = moment(date).format("YYYY-MM-DD");

  const handleSelectValueChange = (event) => {
    dispatch(actionResetPhoto());
    dispatch(actionResetVideo());
    dispatch(actionUpdateEventInput("id", event.target.value));
    dispatch(actionGetOneEvent());
  };

  const handleImageState = (fileName, fileUrl) => {
    const data = {
      id: 0,
      title: fileName,
      url: fileUrl,
      event_id: id,
    };
    dispatch(actionSetPhotoInfos(data));
  };

  const handleVideoState = (fileName, fileUrl) => {
    const data = {
      id: 0,
      title: fileName,
      url: fileUrl,
      event_id: id,
    };
    dispatch(actionSetVideoInfos(data));
  };

  const handleChange = (key, value) => {
    dispatch(actionUpdateEventInput(key, value));
  };

  const handleDeleteVideo = () => {
    dispatch(actionRemoveVideoFromFirebase());
  };

  const handleDeletePhoto = () => {
    dispatch(actionRemovePhotoFromFirebase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(actionUpdateEvent());
    selectRef.current.value = "";
  };

  return (
    <>
      <Helmet>
        <title>Admin - Event</title>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href="/admin/event/update" />
      </Helmet>
      <div className="updateEvent-page">
        <h3 className="updateEvent-page__title">Modifier un évènement</h3>
        <form action="" className="updateEvent-page__form">
          <h3 className="updateEvent-page__form-title">
            Sélectionner l&apos;évènement
          </h3>
          <select
            ref={selectRef}
            id="pet-select"
            className="updateEvent-page__form-select"
            onChange={handleSelectValueChange}
          >
            <option value="">--Choisissez un évènement--</option>
            {allEvents.map((item) => (
              <option value={item.id} key={item.id}>
                {item.title}
              </option>
            ))}
          </select>
        </form>
        <form className="updateEvent-page__formTwo" onSubmit={handleSubmit}>
          <div className="updateEvent-page__formTwo-container">
            <label className="updateEvent-page__formTwo-label">
              Nom de l&apos;évènement
              <input
                className="updateEvent-page__formTwo-input"
                required
                type="text"
                value={title}
                id="name_input"
                name="title"
                placeholder="Nom de l'évènement"
                onChange={(event) => handleChange("title", event.target.value)}
              />
            </label>
            <label className="updateEvent-page__formTwo-label">
              Date de l&apos;évènement
              <input
                required
                className="updateEvent-page__formTwo-input"
                type="date"
                value={dateInFormat}
                min={moment().format("YYYY-MM-DD")}
                id="name_input"
                name="date"
                onChange={(event) => handleChange("date", event.target.value)}
              />
            </label>
            <label className="updateEvent-page__formTwo-label">
              Nom du groupe
              <input
                className="updateEvent-page__formTwo-input"
                type="text"
                value={group}
                id="name_input"
                name="group"
                placeholder="Nom du groupe"
                onChange={(event) => handleChange("group", event.target.value)}
              />
            </label>
          </div>
          <div className="updateEvent-page__formTwo-container">
            <label className="addEvent-page__form-label-textarea">
              Description de l&apos;évènement
              <textarea
                className="addEvent-page__form-textarea"
                name="description"
                rows="8"
                cols="100"
                placeholder="Description de l'évènement"
                onChange={(event) =>
                  handleChange("description", event.target.value)
                }
                value={description}
              />
            </label>
          </div>
          <div className="updateEvent-page__formTwo-file-container">
            <div className="updateEvent-page__formTwo-file-container-content">
              {isInputPhotoDisabled ? (
                <Tooltip
                  title="Vous devez d'abord supprimer l'ancienne image"
                  placement="bottom"
                >
                  <span>
                    <FileInput
                      name="image"
                      label="Ajouter une image"
                      handleInputState={handleImageState}
                      setEventIsDisabled={setIsDisabled}
                      accept="image/*,.pdf"
                      isInputDisabled={isInputPhotoDisabled}
                    />
                  </span>
                </Tooltip>
              ) : (
                <FileInput
                  name="image"
                  label="Ajouter une image"
                  handleInputState={handleImageState}
                  setEventIsDisabled={setIsDisabled}
                  accept="image/*,.pdf"
                  isInputDisabled={isInputPhotoDisabled}
                />
              )}
              {photo.title && (
                <div className="updateEvent-page__formTwo-file-container-content-img">
                  <img
                    src={photo.url}
                    alt={photo.title}
                    className="updateEvent-page__formTwo-file-container-content-img-preview"
                  />
                  <p>{photo.title.split(".")[0]}</p>
                  <button
                    type="button"
                    className="delete"
                    onClick={handleDeletePhoto}
                  >
                    <FontAwesomeIcon icon={faTrash} size="2x" />
                  </button>
                </div>
              )}
            </div>
            <div className="updateEvent-page__formTwo-file-container-content">
              {isInputVideoDisabled ? (
                <Tooltip
                  title="Vous devez d'abord supprimer l'ancienne vidéo"
                  placement="bottom"
                >
                  <span>
                    <FileInput
                      name="video"
                      label="Ajouter une vidéo"
                      handleInputState={handleVideoState}
                      setEventIsDisabled={setIsDisabled}
                      accept="video/*"
                      isInputDisabled={isInputVideoDisabled}
                    />
                  </span>
                </Tooltip>
              ) : (
                <FileInput
                  name="video"
                  label="Ajouter une vidéo"
                  handleInputState={handleVideoState}
                  setEventIsDisabled={setIsDisabled}
                  accept="video/*"
                  isInputDisabled={isInputVideoDisabled}
                />
              )}
              {video.title && (
                <div className="updateEvent-page__formTwo-file-container-content-img">
                  <p>{video.title.split(".")[0]}</p>
                  <button
                    type="button"
                    className="delete"
                    onClick={handleDeleteVideo}
                  >
                    <FontAwesomeIcon icon={faTrash} size="2x" />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="updateEvent-page__formTwo-button">
            {isDisabled ? (
              <Tooltip title="Veuillez d'abord ajouter l'image ou la vidéo">
                <span>
                  <Button
                    classname="normal-button validate"
                    type="submit"
                    name="Modifier l'évènement"
                    disabled
                  />
                </span>
              </Tooltip>
            ) : (
              <Button
                classname="normal-button validate"
                type="submit"
                name="Modifier l'évènement"
              />
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default UpdateEvent;

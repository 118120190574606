import React, { useState, useEffect, useRef } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { storage } from "../../../firebase";
import "./fileInput.scss";

function FileInput({
  label,
  name,
  handleInputState,
  setEventIsDisabled,
  accept,
  isInputDisabled,
}) {
  const inputRef = useRef(null);
  const [fileUpload, setFileUpload] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (fileUpload) {
      setIsDisabled(false);
      if (setEventIsDisabled) {
        setEventIsDisabled(true);
      }
    } else {
      setIsDisabled(true);
      if (setEventIsDisabled) {
        setEventIsDisabled(false);
      }
    }
  }, [fileUpload]);

  const uploadFile = async () => {
    if (fileUpload == null) return;
    setIsDisabled(true);
    const fileName = fileUpload.name + v4();
    const imageRef = ref(
      storage,
      name === "image" ? `images/${fileName}` : `videos/${fileName}`
    );
    await uploadBytes(imageRef, fileUpload);
    getDownloadURL(imageRef).then((url) => {
      handleInputState(fileName, url);
    });
    alert(`${name} enregistrée`);
    setFileUpload(null);
    inputRef.current.value = "";
  };

  return (
    <div className="fileInput__container">
      <h3 className="fileInput__title">{label}</h3>
      <input
        ref={inputRef}
        type="file"
        onChange={(event) => {
          setFileUpload(event.target.files[0]);
        }}
        className="fileInput__input"
        accept={accept}
        disabled={isInputDisabled}
      />
      <button
        disabled={isDisabled}
        type="button"
        className={
          isDisabled ? "fileInput__button-disabled" : "fileInput__button"
        }
        onClick={uploadFile}
      >
        Ajouter
      </button>
    </div>
  );
}

export default FileInput;
